
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine } from "@/utils/common";
import { Table } from "@/utils/decorator";
import RecordList from "./components/record-list.vue";
import { formDownload } from "../../utils/tools";

const customer = namespace("customer");
const base = namespace("base");

@Component({
  components: { RecordList }
})
@Table("loadListData")
export default class ClueList extends Vue {
  @customer.Action getClueList;
  @customer.Action getClueDetail;
  @customer.Action getAllItems;
  @base.Action getDictionaryList;
  emptyToLine = emptyToLine;
  orderList: any[] = [];
  searchForm = {
    status_in: [0, 1],
    item_codes: [],
    search_key: "",
    bind_saas_status: "",
    factory_name: "",
    work_type: "",
    effective_date_start: "",
    effective_date_end: "",
    effectDate: [],
    contact_status: "",
    next_arrange_date_start: "",
    next_arrange_date_end: "",
    next_arrange_date: []
  };
  allItemList: any[] = [];
  clueViewData: any[] = [];
  dictionaryList: any[] = [];
  get storeType() {
    return [
      {
        value: 0,
        label: "未开通"
      },
      {
        value: 1,
        label: "已开通"
      }
    ];
  }
  get sourceList() {
    return [
      {
        value: 1,
        label: "车辆检测"
      },
      {
        value: 2,
        label: "养车日历"
      },
      {
        value: 3,
        label: "门店检测"
      }
    ];
  }
  get viewList() {
    return [
      {
        value: 0,
        label: "否"
      },
      {
        value: 1,
        label: "是"
      }
    ];
  }
  get orderStatusList() {
    return [
      {
        value: 0,
        label: "未预约"
      },
      {
        value: 1,
        label: "已预约"
      },
      {
        value: 2,
        label: "已取消"
      }
    ];
  }
  defaultExpend: boolean = false;
  showClueView: boolean = false;
  showRecordList: boolean = false;
  currentClueOrder: any = {};
  created() {
    this.init();
  }
  handleExportData() {
    formDownload(`${process.env.VUE_APP_FACTORY_API}/saas/clue/exportOpClues`, this.searchForm);
  }
  async init() {
    this.loadListData();
    const { data } = await this.getAllItems();
    data.forEach(d => {
      d.value = d.item_code;
      d.label = d.item_name;
    });
    this.allItemList = data;
    this.dictionaryList = await this.getDictionaryList("store_type");
  }
  @Table.LoadDataMethod()
  loadListData() {
    return this.getClueList({
      ...this.searchForm,
      page: this.mix_pageNum,
      page_size: this.mix_pageSize
    }).then(data => {
      this.orderList = this.$safeValue(data, "data.data", []);
      this.orderList.forEach(item => {
        if (item.clue_items && item.clue_items.length > 0) {
          let _items = [];
          item.clue_items.forEach(i => _items.push(i.item_name));
          item.clue_items_label = _items.join(" | ");
        }
      });
      return data;
    });
  }
  seletedStatus: string = "";
  get contactList() {
    return [
      {
        value: 1,
        label: "未沟通"
      },
      {
        value: 2,
        label: "已沟通"
      }
    ];
  }
  get searchList() {
    return [
      {
        label: "关键字",
        type: "input",
        value: "",
        prop: "search_key",
        placeholder: "车牌号/客户姓名/手机号"
      },
      {
        label: "服务项目",
        type: "select",
        selectList: this.allItemList,
        value: this.seletedStatus,
        prop: "item_codes",
        multiple: true
      },
      {
        label: "线索状态",
        type: "select",
        selectList: this.orderStatusList,
        value: [0, 1],
        noKeepOld: true,
        prop: "status_in",
        multiple: true
      },
      {
        label: "门店名称",
        type: "input",
        value: "",
        prop: "factory_name",
        placeholder: "请输入门店"
      },
      {
        label: "门店类型",
        type: "select",
        selectList: this.dictionaryList,
        value: "",
        noKeepOld: true,
        prop: "work_type"
      },
      {
        label: "门店状态",
        type: "select",
        selectList: this.storeType,
        value: "",
        noKeepOld: true,
        prop: "bind_saas_status"
      },
      {
        label: "线索生效时间",
        type: "daterange",
        value: (this.searchForm.effective_date_start && this.searchForm.effectDate) || "",
        prop: "effectDate"
      },
      {
        label: "线索来源",
        type: "select",
        selectList: this.sourceList,
        value: "",
        noKeepOld: true,
        prop: "source"
      },
      {
        label: "沟通状态",
        type: "select",
        selectList: this.contactList,
        value: "",
        noKeepOld: true,
        prop: "contact_status"
      },
      {
        label: "下次预约时间",
        type: "daterange",
        value: (this.searchForm.next_arrange_date_start && this.searchForm.next_arrange_date) || "",
        prop: "next_arrange_date"
      },
      {
        label: "客户是否查看",
        type: "select",
        selectList: this.viewList,
        value: "",
        noKeepOld: true,
        prop: "view_state"
      }
    ];
  }
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        if (item.prop === "effectDate") {
          const { start, end } = this.formatDate(item);
          this.searchForm["effective_date_start"] = start;
          this.searchForm["effective_date_end"] = end;
        } else if (item.prop === "next_arrange_date") {
          const { start, end } = this.formatDate(item);
          this.searchForm["next_arrange_date_start"] = start;
          this.searchForm["next_arrange_date_end"] = end;
        }
        this.searchForm[item.prop] = item.value;
      }
    });
    this.loadListData();
  }
  formatDate(dateArr) {
    let _start = "";
    let _end = "";
    if (dateArr.value && dateArr.value[0]) {
      _start = dateArr.value[0].slice(0, 4) + "-" + dateArr.value[0].slice(4, 6) + "-" + dateArr.value[0].slice(6, 8);
    }
    if (dateArr.value && dateArr.value[1]) {
      _end = dateArr.value[1].slice(0, 4) + "-" + dateArr.value[1].slice(4, 6) + "-" + dateArr.value[1].slice(6, 8);
    }
    return { start: _start, end: _end };
  }
  handleView(row, type = "") {
    this.currentClueOrder = row;
    if (type) {
      this.showRecordList = true;
    } else {
      this.showClueView = true;
      const { id: clue_id } = row;
      this.getClueDetail({ clue_id }).then(({ data }) => {
        this.clueViewData = data;
      });
    }
  }
}
