
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { emptyToLine } from "@/utils/common";
import { namespace } from "vuex-class";

const customer = namespace("customer");
@Component({
  components: {}
})
export default class AddRecord extends Vue {
  @customer.Action getCLueContactReocrds;
  @Prop()
  show: boolean;
  @Prop()
  clue_id: string;
  formData: any = {
    contact_type: "",
    arrange_type: "",
    next_arrange_date: "",
    remark: ""
  };
  emptyToLine = emptyToLine;
  recordList: any = [];
  page: number = 0;
  stopLoad: boolean = false;
  loading: boolean = false;
  get contact_typeList() {
    return [
      {
        value: 1,
        label: "电话通知"
      },
      {
        value: 2,
        label: "短信通知"
      },
      {
        value: 3,
        label: "微信通知"
      }
    ];
  }
  get arrange_typeList() {
    return [
      {
        value: 1,
        label: "下次预约"
      },
      {
        value: 2,
        label: "无需预约"
      }
    ];
  }
  async created() {}
  mounted() {}
  async init() {}
  loadListData() {
    return [];
  }
  handleCancel() {
    this.$emit("update:show", false);
  }
  async loadMore() {
    ++this.page;
    if (!this.stopLoad) {
      await this.getCLueReocrds();
    }
  }
  async getCLueReocrds() {
    const sendData = {
      page: this.page,
      page_size: 10,
      clue_id: this.clue_id
    };
    this.loading = true;
    const { data } = await this.getCLueContactReocrds(sendData);
    this.loading = false;
    this.recordList = this.recordList.concat(data.data);
    if (this.recordList.length == data.total) {
      this.stopLoad = true;
    }
  }
}
